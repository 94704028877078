import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";

export function* fetchAllDeviceTransactions() {
  yield takeEvery(actions.FETCH_DEVICE_TRANSACTIONS, function*(action) {
    try {
      const response = yield call(
        API.investors.allDeviceTransactions,
        action.payload
      );
      yield put({
        type: actions.FETCH_DEVICES_TRANSACTION_SUCCESS,
        deviceTransactions: response?.data || [],
      });

      if (action.spinHandler) yield call(action.spinHandler);
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
      if (action.spinHandler) yield call(action.spinHandler);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(fetchAllDeviceTransactions)]);
}
