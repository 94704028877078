/**
 * Apps selectors
 */

import { createSelector } from 'reselect';

const selectAppVersions = state => state.Appversion;

const getAppVersions = () => createSelector(
    selectAppVersions,
    (appVersionState) => appVersionState.get('apps')
);

const getAppUsers = () => createSelector(
    selectAppVersions,
    (appVersionState) => appVersionState.get('users')
);

const getConfigs = () => createSelector(
    selectAppVersions,
    (appVersionState) => {
        return { schools: appVersionState.get('schools'),
        grades: appVersionState.get('grades'),
        students: appVersionState.get('students'),
        roles: appVersionState.get('roles')}
    }
);

export {
    getAppVersions,
    getAppUsers,
    getConfigs
};
