const actions = {
  FETCH_DEVICE_TRANSACTIONS: "FETCH_DEVICE_TRANSACTIONS",
  FETCH_DEVICES_TRANSACTION_SUCCESS: "FETCH_DEVICES_TRANSACTION_SUCCESS",

  fetchDeviceTransactions: (payload, spinHandler) => {
    return {
      type: actions.FETCH_DEVICE_TRANSACTIONS,
      payload,
      spinHandler,
    };
  },
};
export default actions;
