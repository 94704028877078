const actions = {
    FETCH_ORGANIZATIONS: 'FETCH_ORGANIZATIONS',
    FETCH_ORGANIZATIONS_SUCCESS: 'FETCH_ORGANIZATIONS_SUCCESS',
    SAVE_ORGANIZATION: 'SAVE_ORGANIZATION',
    DELETE_ORGANIZATION: 'DELETE_ORGANIZATION',
    FETCH_ORGANIZATION_DETAILS: 'FETCH_ORGANIZATION_DETAILS',
    FETCH_ORGANIZATION_DETAILS_SUCCESS: 'FETCH_ORGANIZATION_DETAILS_SUCCESS',
    SET_FILTER: 'SET_FILTER',
    RESET: "RESET",
    GET_ALL_ORGANIZATIONS:'GET_ALL_ORGANIZATIONS',
    GET_ALL_ORGANIZATIONS_SUCCESS:'GET_ALL_ORGANIZATIONS_SUCCESS',
    fetchOrganizations: (payload,spinHandler) => ({
      type: actions.FETCH_ORGANIZATIONS,
      payload,
      spinHandler
    }),
    getAllOrganizations: (spinHandler) => ({
      type: actions.GET_ALL_ORGANIZATIONS,
      spinHandler,
    }),
    saveOrganization: (payload, organizationId, cb) => ({
      type: actions.SAVE_ORGANIZATION,
      payload,
      organizationId,
      cb    
    }),
    deleteOrganization: (payload, organizationId, spinHandler) => ({
      type: actions.DELETE_ORGANIZATION,
      payload,
      organizationId,
      spinHandler
    }),
    fetchSingleOrganization: (organizationId, spinHandler) => ({
      type: actions.FETCH_ORGANIZATION_DETAILS,
      organizationId,
      spinHandler
    }),
    setFilter: (key) => ({
      type: actions.SET_FILTER,
      key
    }),
    reset: () => ({
      type: actions.RESET
    }),
  };
  export default actions;
  