const actions = {
  FETCH_SINGLE_CLASSROOM: "FETCH_SINGLE_CLASSROOM",
  FETCH_SINGLE_CLASSROOM_SUCCESS: "FETCH_SINGLE_CLASSROOM_SUCCESS",
  FETCH_SINGLE_CLASSROOM_CHAT: "FETCH_SINGLE_CLASSROOM_CHAT",
  FETCH_SINGLE_CLASSROOM_CHAT_SUCCESS: "FETCH_SINGLE_CLASSROOM_CHAT_SUCCESS",
  FETCH_CLASSROOMS: "FETCH_CLASSROOMS",
  FETCH_CLASSROOMSV: "FETCH_CLASSROOMSV",
  FETCH_CLASSROOMS_SUCCESS: "FETCH_CLASSROOMS_SUCCESS",
  FETCH_CLASSROOMS_SUCCESSV: "FETCH_CLASSROOMS_SUCCESSV",
  FETCH_MY_CLASSROOMS: "FETCH_MY_CLASSROOMS",
  FETCH_MY_CLASSROOMS_SUCCESS: "FETCH_MY_CLASSROOMS_SUCCESS",
  EDIT_SINGLE_CLASSROOM: "EDIT_SINGLE_CLASSROOM",
  EDIT_SINGLE_CLASSROOM_SUCCESS: "EDIT_SINGLE_CLASSROOM_SUCCESS",
  ADD_EXISTING_CLASSROOM: "ADD_EXISTING_CLASSROOM",
  ADD_EXISTING_CLASSROOM_SUCCESS: "ADD_EXISTING_CLASSROOM_SUCCESS",
  DELETE_SINGLE_CLASSROOM_FILE: "DELETE_SINGLE_CLASSROOM_FILE",
  DELETE_SINGLE_CLASSROOM_FILE_SUCCESS: "DELETE_SINGLE_CLASSROOM_FILE_SUCCESS",
  PUBLISH_LESSON: "PUBLISH_LESSON",
  PUBLISH_LESSON_SUCCESS: "PUBLISH_LESSON_SUCCESS",
  ADD_STUDENT: "ADD_STUDENT",
  ADD_STUDENT_SUCCESS: "ADD_STUDENT_SUCCESS",
  SEND_MESSAGE: "SEND_MESSAGE",
  SEND_MESSAGE_SUCCESS: "SEND_MESSAGE_SUCCESS",
  SET_FILTER: "SET_FILTER",
  SET_FILTER_BOOKS: "SET_FILTER_BOOKS",
  SET_LAUNCH_RATING: "SET_LAUNCH_RATING",
  ADD_EXISTING_USER: "ADD_EXISTING_USER",
  DRAG_LESSONS: "DRAG_LESSONS",
  DRAG_LESSONS_SUCCESS: "DRAG_LESSONS_SUCCESS",
  FETCH_CLASSROOM_UNIT_DETAILS:"FETCH_CLASSROOM_UNIT_DETAILS",
  FETCH_CLASSROOM_UNIT_DETAILS_SUCCESS:"FETCH_CLASSROOM_UNIT_DETAILS_SUCCESS",
  fetchClassrooms: (spinHandler, payload = {}) => ({
    type: actions.FETCH_CLASSROOMS,
    spinHandler,
    payload,
  }),
  fetchClassroomsv: (spinHandler, payload = {}) => ({
    type: actions.FETCH_CLASSROOMSV,
    spinHandler,
    payload,
  }),
  fetchMyClassrooms: (spinHandler, payload = {}) => ({
    type: actions.FETCH_MY_CLASSROOMS,
    spinHandler,
    payload,
  }),

  fetchSingleClassroom: (classroom_id, spinHandler) => ({
    type: actions.FETCH_SINGLE_CLASSROOM,
    classroom_id,
    spinHandler,
  }),
  getSingleClassroomChat: (classroom_id, spinHandler) => ({
    type: actions.FETCH_SINGLE_CLASSROOM_CHAT,
    classroom_id,
    spinHandler,
  }),
  editSingleCourse: (classroom_id, payload, spinHandler) => ({
    type: actions.EDIT_SINGLE_CLASSROOM,
    classroom_id,
    payload,
    spinHandler,
  }),
  addExistingCourse: (payload, classroom_id, spinHandler) => ({
    type: actions.ADD_EXISTING_CLASSROOM,
    payload,
    classroom_id,
    spinHandler,
  }),
  deleteSingleCourse: (payload, classroom_id, resourceId, spinHandler) => ({
    type: actions.DELETE_SINGLE_CLASSROOM_FILE,
    payload,
    classroom_id,
    resourceId,
    spinHandler,
  }),
  createClassroomStudent: (payload, classroom_id, students, spinHandler) => ({
    type: actions.ADD_STUDENT,
    payload,
    classroom_id,
    students,
    spinHandler,
  }),
  dragLessons: (payload, classroom_id, spinHandler) => ({
    type: actions.DRAG_LESSONS,
    payload,
    classroom_id,
    spinHandler,
  }),
  addExistingUser: (
    new_student_id,
    classroom_id,
    studentList,
    spinHandler
  ) => ({
    type: actions.ADD_EXISTING_USER,
    new_student_id,
    classroom_id,
    studentList,
    spinHandler,
  }),
  fetchSingleListUnit: (payload, spinHandler) => ({
    type: actions.FETCH_CLASSROOM_UNIT_DETAILS,
    payload,
    spinHandler,
  }),
  publishLesson: (classroom_id, lesson_id, spinHandler) => ({
    type: actions.PUBLISH_LESSON,
    classroom_id,
    lesson_id,
    spinHandler,
  }),
  sendMessage: (classroom_id, payload, spinHandler) => ({
    type: actions.SEND_MESSAGE,
    classroom_id,
    payload,
    spinHandler,
  }),
  setFilter: (key) => ({
    type: actions.SET_FILTER,
    key,
  }),
  setFilterBooks: (key) => ({
    type: actions.SET_FILTER_BOOKS,
    key,
  }),
  launchRating: (payload, spinHandler) => ({
    type: actions.SET_LAUNCH_RATING,
    payload,
    spinHandler,
  }),
};

export default actions;
