import styled from 'styled-components';
import WithDirection from '../../settings/withDirection';

const ModalWrapper = styled.div`
    display: inherit;    
    .ant-row{
      padding: 2px 0;
      .ant-form-item-label label{
        width: 131px;
        display: inline-block;
      }
      .ant-upload button{
        width: 280px;
      }
    }
    .dummy{
      line-height: 1px;
    }
`;

export default WithDirection(ModalWrapper);
