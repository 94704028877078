import { all, call, takeEvery, put, fork, select } from "redux-saga/effects";
import { push } from "react-router-redux";
import Moment from "moment";

import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";
import { listAllGroups } from "./selecters";
const getItems = state => state.Group;

export function* fetchConfigs() {
  yield takeEvery(actions.FETCH_CONFIGS, function*(action) {
    try {
      const schools = yield call(API.common.schools);
      // const grades = yield call(API.common.grades);
      // const subjects = yield call(API.subjects.list);
      yield put({
        type: actions.FETCH_CONFIGS_SUCCESS,
        schools: schools && schools.data.schools ? schools.data.schools : [],
        grades: /*grades && grades.data.grades ? grades.data.grades : */ [],
        subjects: []
        // subjects && subjects.data.subjects ? subjects.data.subjects : [],
      });
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

const formatGroup = apiResponse => {
  let formattedOutput = [];
  apiResponse.forEach(item => {
    formattedOutput.push({
      _id: item._id,
      title: item.name,
      users: item.occupants_ids,
      noOfUsers: item.occupants_ids.length,
      createdAt: Moment(item.created_at).format("DD-MMM-YYYY hh:mm a"),
      school: (item && item.data && item.data.school) || undefined,
      // accessionNumbers: item.accessionNo,
      classroom: (item && item.data && item.data.classroom) || undefined,
      organization: (item && item.data && item.data.organization) || undefined
    });
  });
  return formattedOutput;
};

const formatUserList = apiResponse => {
  let formattedOutput = [];
  apiResponse.forEach(item => {
    if (item.quickblox && item.quickblox.id) {
      formattedOutput.push({
        name: `${item.firstName} ${item.lastName || ""}`,
        _id: item.quickblox.id
      });
    }
  });
  return formattedOutput;
};

const formatHistory = apiResponse => {
  let formattedOutput = [];
  apiResponse.forEach(item => {
    formattedOutput.push({
      _id: item._id,
      groudID: item.chat_dialog_id,
      dateSent: Moment(item.date_sent * 1000).format("hh:mm a DD-MM-YYYY"),
      deliveredIDs: item.delivered_ids,
      message: item.message || "[image]",
      readIDs: item.read_ids,
      senderID: item.sender_id
    });
  });
  return formattedOutput;
};

export function* fetchGroups() {
  yield takeEvery(actions.LIST_GROUP, function*(action) {
    try {
      if (action.payload.offset === 0) {
        yield put({
          type: actions.LIST_GROUP_SUCCESS,
          payload: [],
          total: 0
        });
      }

      const groups = yield select(listAllGroups());
      yield put({
        type: actions.SET_LOADING,
        loading: true
      });

      const resp = yield call(API.groups.list, action.payload);

      if (
        resp.data &&
        resp.data.users &&
        resp.data.users.items &&
        resp.data.users.items.length
      ) {
        let payload = [];
        if (Array.isArray(groups) && action.payload.offset !== 0) {
          payload = [...groups, ...formatGroup(resp.data.users.items)];
        } else {
          payload = formatGroup(resp.data.users.items);
        }
        yield put({
          type: actions.LIST_GROUP_SUCCESS,
          payload,
          total: resp.data.totalEntries
        });
      }
      yield put({
        type: actions.SET_LOADING,
        loading: false
      });
    } catch (err) {
      yield put({
        type: actions.SET_LOADING,
        loading: false
      });
      console.log(err);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchHistory() {
  yield takeEvery(actions.GET_CHAT_HISTORY, function*(action) {
    try {
      yield put({
        type: actions.SET_LOADING,
        loading: true
      });

      const resp = yield call(API.groups.history, action.payload);

      if (
        resp.data &&
        resp.data.users &&
        resp.data.users.data &&
        resp.data.users.data.items &&
        resp.data.users.data.items.length
      ) {
        yield put({
          type: actions.GET_CHAT_HISTORY_SUCCESS,
          payload: formatHistory(resp.data.users.data.items)
        });
      } else {
        // Notification("error", "No Details Found!");
      }
      yield put({
        type: actions.SET_LOADING,
        loading: false
      });
    } catch (err) {
      yield put({
        type: actions.SET_LOADING,
        loading: false
      });

      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchUsers() {
  yield takeEvery(actions.FETCH_USERS_FOR_GROUP, function*(action) {
    try {
      const resp = yield call(API.users.list, action.payload);
      if (resp.data && resp.data?.data && resp.data?.data.length) {
        yield put({
          type: actions.FETCH_USERS_FOR_GROUP_SUCCESS,
          payload: formatUserList(resp.data?.data)
        });
        yield call(action.spinner, false);
      }
    } catch (err) {
      yield call(action.spinner, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* createGroup() {
  yield takeEvery(actions.CREATE_GROUP, function*(action) {
    try {
      yield put({
        type: actions.SET_LOADING,
        loading: true
      });

      const resp = yield call(API.groups.add, action.payload);

      yield put({
        type: actions.SET_LOADING,
        loading: false
      });

      if (resp.data.success) {
        yield put({
          type: actions.CREATE_GROUP_SUCCESS,
          completed: true
        });
        Notification("success", "New group has been created!");

        yield put({
          type: actions.LIST_GROUP,
          payload: { limit: 50, offset: 0 }
        });
      }
    } catch (err) {
      yield put({
        type: actions.SET_LOADING,
        loading: false
      });

      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* editGroup() {
  yield takeEvery(actions.EDIT_GROUP, function*(action) {
    try {
      yield put({
        type: actions.SET_LOADING,
        loading: true
      });

      const resp = yield call(API.groups.update, action.payload);

      yield put({
        type: actions.SET_LOADING,
        loading: false
      });

      if (resp.success) {
        yield put({
          type: actions.EDIT_GROUP_SUCCESS,
          completed: true
        });

        Notification("success", "Successfully edited group details");

        yield put({
          type: actions.LIST_GROUP,
          payload: { limit: 50, offset: 0 }
        });
      } else {
        Notification("error", resp.message);
      }
    } catch (err) {
      yield put({
        type: actions.SET_LOADING,
        loading: false
      });

      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteGroup() {
  yield takeEvery(actions.DELETE_GROUP, function*(action) {
    try {
      yield put({
        type: actions.SET_LOADING,
        loading: true
      });

      const resp = yield call(API.groups.delete, action.payload);
      yield put({
        type: actions.SET_LOADING,
        loading: false
      });

      if (resp.data.success) {
        yield put({
          type: actions.DELETE_GROUP_SUCCESS
        });
        Notification("success", "Group has been deleted successfully");
        yield call(action.cb, true);
      } else {
        Notification("error", resp.data.message);
        yield call(action.cb, false);
      }

      yield put({
        type: actions.LIST_GROUP,
        payload: { limit: 50, offset: 0 }
      });
    } catch (err) {
      yield call(action.cb, false);
      yield put({
        type: actions.SET_LOADING,
        loading: false
      });

      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* sendMessage() {
  yield takeEvery(actions.SEND_CHAT_REPLY, function*(action) {
    try {
      const resp = yield call(
        API.groups.sendMessage,
        action.groupId,
        action.payload
      );
      if (resp?.data?.success == false) {
        Notification("error", "Failed to send message");
        yield call(action.spinHandler, false);
      } else {
        Notification("success", "Message has been sent successfully");
        yield call(action.spinHandler, true);
      }
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err?.error?.message || "Session timed out. Please login again"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([fork(fetchGroups)]);
  yield all([fork(fetchUsers)]);
  yield all([fork(createGroup)]);
  yield all([fork(editGroup)]);
  yield all([fork(deleteGroup)]);
  yield all([fork(fetchHistory)]);
  yield all([fork(fetchConfigs)]);
  yield all([fork(sendMessage)]);
}
