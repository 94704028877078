import { fromJS } from 'immutable';
import actions from './actions';

const initState = fromJS({
  apps: null,
  roles: null,
  schools: null,
  students:null,
  grades: null,
  users: null,
  key: null
});

export default function appVersionReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_APPS_SUCCESS:
      return state
        .set('apps', action.apps);
    case actions.FETCH_APP_USERS_SUCCESS:
      return state
        .set('users', action.users);
    case actions.FETCH_APP_CONFIGS_SUCCESS:
      return state
        .set('roles', action.roles)
        .set('schools', action.schools)
        .set('grades', action.grades)
        .set('students',action.students);
    case actions.SET_FILTER:
      return state
        .set('key', action.key);
    case actions.RESET:
      return state
        .set('apps', null);
    default:
      return state;
  }
}
