import { Select } from 'antd';
import { AntSelect } from './styles/select.style';
import WithDirection from '../../settings/withDirection';

const WDSelect = AntSelect(Select);
const isoSelect = WithDirection(WDSelect);
const SelectOption = Select.Option;
const filteroption = {
  showSearch: true,
  optionFilterProp: "children",
  filterOption: (input, option) =>
    option?.props?.children
      ?.toString()
      ?.toLowerCase()
      ?.indexOf(input?.toLowerCase()) >= 0,
};

export default isoSelect;
export { SelectOption,filteroption };
