const actions = {
  GET_ALL_MENU_ITEM: "GET_ALL_MENU_ITEM",
  SET_MENU_ITEMS: "SET_MENU_ITEMS",
  ADD_ROLE: "ADD_ROLE",
  GET_ROLES: "GET_ROLES",
  GET_ALL_ROLES: "GET_ALL_ROLES",
  SET_ROLES: "SET_ROLES",
  DELETE_ROLE: "DELETE_ROLE",
  UPDATE_ROLE: "UPDATE_ROLE",

  fetchMenuItem: (callBack) => ({
    type: actions.GET_ALL_MENU_ITEM,
    callBack,
  }),
  setMenuItem: (data) => ({
    type: actions.SET_MENU_ITEMS,
    data,
  }),
  addRole: (payload, callBack) => ({
    type: actions.ADD_ROLE,
    payload,
    callBack,
  }),
  fetchRole: (callBack) => ({
    type: actions.GET_ROLES,
    callBack,
  }),
  setRoles: (data) => ({
    type: actions.SET_ROLES,
    data,
  }),
  fetchAllRoles: (callBack) => ({
    type: actions.GET_ALL_ROLES,
    callBack,
  }),
  deleteRole: (id, callBack) => ({
    type: actions.DELETE_ROLE,
    id,
    callBack,
  }),
  updateRole: (id, payload, callBack) => ({
    type: actions.UPDATE_ROLE,
    id,
    payload,
    callBack,
  }),
};
export default actions;
