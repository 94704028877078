import React from 'react';
import config from "../settings";
import io from "socket.io-client"; 
const API_URL = `${config.baseUrl}`;

export const socket = io(`${API_URL}/messaging`, {
    auth: {
        token: localStorage.getItem('id_token')
      },
    transports: ['websocket'],
    upgrade: false,
    autoConnect: false
});
export const SocketContext = React.createContext();