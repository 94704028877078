import { fromJS } from "immutable";
import actions from "./actions";

const initState = fromJS({
    deviceTransactions: null,
});

export default function deviceReducer(state = initState, action) {
    switch (action.type) {
        case actions.FETCH_DEVICES_TRANSACTION_SUCCESS:
            return state.set("deviceTransactions", action.deviceTransactions);
        default:
            return state;
    }
}
