import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";

export function* fetchClassrooms() {
  yield takeEvery(actions.FETCH_CLASSROOMS, function* (action) {
    try {
      yield put({
        type: actions.FETCH_CLASSROOMS_SUCCESS,
        classrooms: [],
      });
      const resp = yield call(API.myClassroom.list, action.payload);
      yield put({
        type: actions.FETCH_CLASSROOMS_SUCCESS,
        classrooms: Array.isArray(resp?.data?.classrooms) ? resp.data.classrooms : [],
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      let errMsg = "Session timed out. Please login again";
      if (typeof err?.error === "string") {
        errMsg = err.error;
      } else if (typeof err?.error?.message === "string") {
        errMsg = err.error.message;
      } else if (typeof err?.message === "string") {
        errMsg = err.message;
      }
      Notification("error", errMsg);
    }
  });
}

export function* fetchClassroomsv() {
  yield takeEvery(actions.FETCH_CLASSROOMSV, function* (action) {
    try {
      yield put({
        type: actions.FETCH_CLASSROOMS_SUCCESSV,
        classrooms: [],
      });
      const resp = yield call(API.classroomAdmin.listAllV, action.payload);
      yield put({
        type: actions.FETCH_CLASSROOMS_SUCCESSV,
        classrooms: Array.isArray(resp?.data?.classrooms) ? resp.data.classrooms : [],
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      let errMsg = "Session timed out. Please login again";
      if (typeof err?.error === "string") {
        errMsg = err.error;
      } else if (typeof err?.error?.message === "string") {
        errMsg = err.error.message;
      } else if (typeof err?.message === "string") {
        errMsg = err.message;
      }
      Notification("error", errMsg);
    }
  });
}

export function* fetchSingleListUnit() {
  yield takeEvery(actions.FETCH_CLASSROOM_UNIT_DETAILS, function* (action) {
    try {
      const resp = yield call(API.myClassroom.units, action.payload);
      yield put({
        type: actions.FETCH_CLASSROOM_UNIT_DETAILS_SUCCESS,
        units: resp.data,
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}
export function* fetchMyClassrooms() {
  yield takeEvery(actions.FETCH_MY_CLASSROOMS, function* (action) {
    try {
      const resp = yield call(API.myClassroom.myList);
      yield put({
        type: actions.FETCH_MY_CLASSROOMS_SUCCESS,
        classrooms: resp.data?.myClassrooms,
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchSingleClassroom() {
  yield takeEvery(actions.FETCH_SINGLE_CLASSROOM, function* (action) {
    try {
      const resp = yield call(
        API.myClassroom.listSingleClassroom,
        action.classroom_id
      );
      yield put({
        type: actions.FETCH_SINGLE_CLASSROOM_SUCCESS,
        singleClassroom: resp.data.classroom,
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* getSingleClassroomChat() {
  yield takeEvery(actions.FETCH_SINGLE_CLASSROOM_CHAT, function* (action) {
    try {
      const resp = yield call(
        API.myClassroom.listSingleClassroomChat,
        action.classroom_id
      );
      yield put({
        type: actions.FETCH_SINGLE_CLASSROOM_CHAT_SUCCESS,
        singleClassroomChat: resp.data.classroom,
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* sendMessage() {
  yield takeEvery(actions.SEND_MESSAGE, function* (action) {
    try {
      const resp = yield call(
        API.myClassroom.sendMessage,
        action.classroom_id,
        action.payload
      );
      yield put({
        type: actions.SEND_MESSAGE_SUCCESS,
        singleClassroomChat: resp.data.classroom,
      });
      const respChat = yield call(
        API.myClassroom.listSingleClassroomChat,
        action.classroom_id
      );
      yield put({
        type: actions.FETCH_SINGLE_CLASSROOM_CHAT_SUCCESS,
        singleClassroomChat: respChat.data.classroom,
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* editSingleCourse() {
  yield takeEvery(actions.EDIT_SINGLE_CLASSROOM, function* (action) {
    try {
      const resp = yield call(
        API.myClassroom.edit,
        action.classroom_id,
        action.payload
      );
      yield put({
        type: actions.EDIT_SINGLE_CLASSROOM_SUCCESS,
        singleClassroom: resp.data,
      });
      const respClass = yield call(
        API.myClassroom.listSingleClassroom,
        action.classroom_id
      );
      yield put({
        type: actions.FETCH_SINGLE_CLASSROOM_SUCCESS,
        singleClassroom: respClass.data.classroom,
      });
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* addExistingCourse() {
  yield takeEvery(actions.ADD_EXISTING_CLASSROOM, function* (action) {
    try {
      const resp = yield call(
        API.myClassroom.allocateResource,
        action.classroom_id,
        action.payload
      );
      yield put({
        type: actions.ADD_EXISTING_CLASSROOM_SUCCESS,
        singleClassroom: resp.data,
      });
      const respClass = yield call(
        API.myClassroom.listSingleClassroom,
        action.classroom_id
      );
      yield put({
        type: actions.FETCH_SINGLE_CLASSROOM_SUCCESS,
        singleClassroom: respClass.data.classroom,
      });
      Notification("success", `${action.payload.type} added successfully`);
      yield call(
        action.spinHandler != undefined ? action.spinHandler : () => { },
        false
      );
    } catch (err) {
      yield call(
        action.spinHandler != undefined ? action.spinHandler : () => { },
        true
      );
      Notification(
        "error",
        err && err.error
          ? err.error.message || err?.error
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* createClassroomStudent() {
  yield takeEvery(actions.ADD_STUDENT, function* (action) {
    try {
      let message;

      const resp = yield call(API.myClassroom.addUser, action.payload);
      if (resp.data.userdata) {
        let data = new FormData();
        // let payload = { classroomId: action.classroom_id, students: [...action.students, resp.data.userdata._id] }
        let students = [...(action.students || []), resp.data.userdata._id];
        data.append("classroomId", action.classroom_id);
        data.append("students", JSON.stringify(students));
        console.log();
        yield call(API.classroomAdmin.update, data, action.classroom_id);
      }
      message = "Student created successfully";
      yield call(action.spinHandler, false);
      const respClass = yield call(
        API.myClassroom.listSingleClassroom,
        action.classroom_id
      );
      yield put({
        type: actions.FETCH_SINGLE_CLASSROOM_SUCCESS,
        singleClassroom: respClass.data.classroom,
      });
      Notification("success", message);
    } catch (err) {
      yield call(action.spinHandler, { status: false });
      //yield call(action.cb, "loader");
      Notification(
        "error",
        err && err.error ? err.error.message : "Error adding student"
      );
    }
  });
}

export function* addExistingUser() {
  yield takeEvery(actions.ADD_EXISTING_USER, function* (action) {
    try {
      let message;
      let data = new FormData();
      data.append("classroomId", action.classroom_id);
      data.append("students", JSON.stringify(action.new_student_id || []));
      yield call(API.classroomAdmin.update, data, action.classroom_id);
      message = "Student created successfully";
      yield call(action.spinHandler, false);
      const respClass = yield call(
        API.myClassroom.listSingleClassroom,
        action.classroom_id
      );
      yield put({
        type: actions.FETCH_SINGLE_CLASSROOM_SUCCESS,
        singleClassroom: respClass.data.classroom,
      });
      Notification("success", message);
    } catch (err) {
      yield call(action.spinHandler, { status: false });
      Notification(
        "error",
        err && err.error ? err.error.message : "Error adding student xyz"
      );
    }
  });
}

export function* deleteSingleCourseFile() {
  yield takeEvery(actions.DELETE_SINGLE_CLASSROOM_FILE, function* (action) {
    try {
      const resp = yield call(
        API.myClassroom.deleteSingle,
        action.payload,
        action.classroom_id,
        action.resourceId
      );
      yield put({
        type: actions.DELETE_SINGLE_CLASSROOM_FILE_SUCCESS,
        singleClassroom: resp.data,
      });
      const respClass = yield call(
        API.myClassroom.listSingleClassroom,
        action.classroom_id
      );
      yield put({
        type: actions.FETCH_SINGLE_CLASSROOM_SUCCESS,
        singleClassroom: respClass.data.classroom,
      });
      Notification("success", `${action.payload.type} deleted successfully`);
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error ? err.error.message : "Error deleting content"
      );
    }
  });
}

export function* publishLesson() {
  yield takeEvery(actions.PUBLISH_LESSON, function* (action) {
    try {
      const resp = yield call(
        API.myClassroom.publishLesson,
        action.classroom_id,
        action.lesson_id
      );
      yield put({
        type: actions.PUBLISH_LESSON_SUCCESS,
        singleClassroom: resp.data,
      });
      const respClass = yield call(
        API.myClassroom.listSingleClassroom,
        action.classroom_id
      );
      yield put({
        type: actions.FETCH_SINGLE_CLASSROOM_SUCCESS,
        singleClassroom: respClass.data.classroom,
      });
      Notification("success", "Lesson published successfully");
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error ? err.error.message : "Error publishing lesson!"
      );
    }
  });
}
export function* launchRating() {
  yield takeEvery(actions.SET_LAUNCH_RATING, function* (action) {
    try {
      const resp = yield call(API.myClassroom.launchRating, action.payload);
      const respClass = yield call(
        API.myClassroom.listSingleClassroom,
        action.payload.classroom
      );
      yield put({
        type: actions.FETCH_SINGLE_CLASSROOM_SUCCESS,
        singleClassroom: respClass.data.classroom,
      });
      yield call(action.spinHandler, false);
      Notification("success", `Rating launched successfully`);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* dragLessons() {
  yield takeEvery(actions.DRAG_LESSONS, function* (action) {
    try {
      const resp = yield call(
        API.myClassroom.dragLessons,
        action.payload,
        action.classroom_id
      );
      // yield put({
      //   type: actions.DRAG_LESSONS_SUCCESS,
      //   singleClassroom: resp.data,
      // });
      const respClass = yield call(
        API.myClassroom.listSingleClassroom,
        action.classroom_id
      );
      yield put({
        type: actions.FETCH_SINGLE_CLASSROOM_SUCCESS,
        singleClassroom: respClass.data.classroom,
      });
      // Notification("success", `Order Changed`);
      // yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchSingleClassroom),
    fork(getSingleClassroomChat),
    fork(sendMessage),
    fork(fetchClassrooms),
    fork(fetchClassroomsv),
    fork(fetchMyClassrooms),
    fork(editSingleCourse),
    fork(addExistingCourse),
    fork(deleteSingleCourseFile),
    fork(createClassroomStudent),
    fork(publishLesson),
    fork(launchRating),
    fork(addExistingUser),
    fork(dragLessons),
    fork(fetchSingleListUnit),
  ]);
}
