const actions = {
  FETCH_USERS_ADMIN: "FETCH_USERS_ADMIN",
  FETCH_USERS_ADMIN_SUCCESS: "FETCH_USERS_ADMIN_SUCCESS",
  SAVE_ADMIN_USER: "SAVE_ADMIN_USER",
  DELETE_ADMIN: "DELETE_ADMIN",
  FETCH_USER_ADMIN_DETAILS: "FETCH_USER_ADMIN_DETAILS",
  FETCH_USER_ADMIN_DETAILS_SUCCESS: "FETCH_USER_ADMIN_DETAILS_SUCCESS",
  FETCH_USER_ADMIN_DETAILS_FAIL: "FETCH_USER_ADMIN_DETAILS_FAIL",
  // FETCH_CONFIGS: "FETCH_CONFIGS",
  // FETCH_CONFIGS_SUCCESS: "FETCH_CONFIGS_SUCCESS",
  UPLOAD_ADMIN_AVATAR: "UPLOAD_ADMIN_AVATAR",
  SET_FILTER: "SET_FILTER",
  CHANGE_ADMIN_STATUS: "CHANGE_ADMIN_STATUS",
  CHANGE_ADMIN_STATUS_SUCCESS: "CHANGE_ADMIN_STATUS_SUCCESS",
  RESET: "RESET",
  DELETE_MULTIPLE: "DELETE_MULTIPLE",
  FETCH_ORGANIZATION: "FETCH_ORGANIZATION",
  FETCH_ORGANIZATION_SUCCESS: "FETCH_ORGANIZATION_SUCCESS",
  FETCH_TEACHER: "FETCH_TEACHER",
  FETCH_TEACHER_SUCCESS: "FETCH_TEACHER_SUCCESS",
  FETCH_ORGANIZATION_SCHOOL: "FETCH_ORGANIZATION_SCHOOL",
  FETCH_ORGANIZATION_SCHOOL_CSTM: "FETCH_ORGANIZATION_SCHOOL_CSTM",
  FETCH_ORGANIZATION_SCHOOL_SUCCESS: "FETCH_ORGANIZATION_SCHOOL_SUCCESS",
  FETCH_ORGANIZATION_COURSE: "FETCH_ORGANIZATION_COURSE",
  FETCH_ORGANIZATION_COURSE_SUCCESS: "FETCH_ORGANIZATION_COURSE_SUCCESS",

  fetchOrganizations: (spinner) => ({
    type: actions.FETCH_ORGANIZATION,
    spinner,
  }),

  fetchOrganizationSchool: (organization_id, spinner) => ({
    type: actions.FETCH_ORGANIZATION_SCHOOL,
    organization_id,
    spinner,
  }),

  fetchOrganizationSchoolCstm: (organization_id, spinner) => ({
    type: actions.FETCH_ORGANIZATION_SCHOOL_CSTM,
    organization_id,
    spinner,
  }),

  fetchOrganizationCourse: (organization_id, spinner) => ({
    type: actions.FETCH_ORGANIZATION_COURSE,
    organization_id,
    spinner,
  }),

  fetchTeacher: (id, spinner) => ({
    type: actions.FETCH_TEACHER,
    spinner,
    id,
  }),
  fetchUsersAdmins: (payload, spinHandler) => ({
    type: actions.FETCH_USERS_ADMIN,
    payload,
    spinHandler,
  }),
  saveAdminUser: (payload, userId, loader) => ({
    type: actions.SAVE_ADMIN_USER,
    payload,
    userId,
    loader,
  }),
  deleteUser: (payload, userId, spinHandler) => ({
    type: actions.DELETE_ADMIN,
    payload,
    userId,
    spinHandler,
  }),
  fetchSingleUser: (userId, spinHandler) => ({
    type: actions.FETCH_USER_ADMIN_DETAILS,
    userId,
    spinHandler,
  }),
  // fetchConfigs: () => ({
  //   type: actions.FETCH_CONFIGS,
  // }),
  uploadAvatar: (payload, userId) => ({
    type: actions.UPLOAD_ADMIN_AVATAR,
    payload,
    userId,
  }),
  setFilter: (key) => ({
    type: actions.SET_FILTER,
    key,
  }),
  changeStatus: (payload, userId, cb) => ({
    type: actions.CHANGE_ADMIN_STATUS,
    payload,
    userId,
    cb,
  }),
  reset: () => ({
    type: actions.RESET,
  }),
  deleteMultiple: (userIds, callBack) => ({
    type: actions.DELETE_MULTIPLE,
    payload: { userIds, callBack },
  }),
};
export default actions;
