import { useEffect, useRef, useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import Tesseract from "tesseract.js";
import "react-image-crop/dist/ReactCrop.css";
import { Button, Form, Modal, Slider } from "antd";

const CropToolModal = ({
  openCropModal,
  setOpenCropModal,
  imgSrc,
  submitImage,
  imgToCrop,
  ...props
}) => {
  const imgRef = useRef(null);
  const { createWorker } = Tesseract;
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [scale, setScale] = useState(1);

  useEffect(
    () => {
      setSubmitting(false);
    },
    [imgSrc]
  );

  async function doOCR(imageBuffer) {
    const worker = await createWorker();
    await worker.loadLanguage("por");
    await worker.initialize("por");
    await worker.loadLanguage("eng");
    await worker.initialize("eng");

    const data = await worker.recognize(imageBuffer);

    await worker.terminate();
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSubmitting(true);
    if (previewCanvasRef.current) {
      previewCanvasRef.current?.toBlob(
        (blob) => {
          let file = new File([blob], "test.jpeg", { type: "image/jpeg" });
          submitImage(file);
        },
        "image/png",
        1
      );
    } else {
      submitImage(imgToCrop);
    }
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale
        );
        doOCR(previewCanvasRef.current.toDataURL());
      }
    },
    100,
    [completedCrop, scale, imgSrc]
  );
  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  function onImageLoad(e) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, props.aspect ?? 1));
  }
  return (
    <>
      <Modal
        visible={openCropModal}
        onCancel={setOpenCropModal}
        closeButton
        title={"Crop Image"}
        // footer={null}
        onOk={handleSubmit}
        // destroyOnClose
        maskClosable={false}
        okButtonProps={{ loading: submitting }}
        closable
        zIndex={10002}
      >
        <div>
          {!!imgSrc && (
            <div>
              <ReactCrop
                style={{ width: "100%", height: "auto" }}
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={props.aspect ?? 1}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{
                    transform: `scale(${scale})`,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
              <Slider
                min={0}
                max={2}
                step={0.1}
                value={scale}
                onChange={(value) => {
                  setScale(Number(value));
                }}
              />
            </div>
          )}
          <div>
            {!!completedCrop && (
              <canvas
                ref={previewCanvasRef}
                style={{
                  border: "1px solid black",
                  objectFit: "contain",
                  width: completedCrop.width,
                  height: completedCrop.height,
                  display: "none",
                }}
              />
            )}
          </div>
          {/* <div>
                {!!completedCrop && (
                  <>
                    <canvas
                      ref={previewCanvasRef}
                      style={{
                        border: "1px solid black",
                        objectFit: "contain",
                        width: completedCrop.width,
                        height: completedCrop.height,
                      }}
                    />
                  </>
                )}
              </div> */}
        </div>
      </Modal>
    </>
  );
};

export default CropToolModal;
