import React, { useEffect, useState } from 'react'
import { Modal } from "antd";


function TeacherMoodal() {
    useEffect(() => {
        (JSON.parse(localStorage.getItem("profile"))?.classrooms.length && !JSON.parse(localStorage.getItem("TeaacherModalShown"))) && setShowModal(true);
        localStorage.setItem(
            "TeaacherModalShown",
            true
        );
    }, []);
    const [showModal, setShowModal] = useState(false);

    return (
        <Modal
            visible={showModal}
            onClose={() => setShowModal(false)}
            // footer={false}
            onOk={() => setShowModal(false)}
            closable
            footer={null} // Removes both OK and Cancel buttons
            onCancel={() => setShowModal(false)}
        >
            <div style={{ textAlign: "center", fontSize: 16 }}>
                {console.log(JSON.parse(localStorage.getItem("profile"))?.firstName)}
                <p>Hello <strong style={{color: "#0c5387"}}>{JSON.parse(localStorage.getItem("profile"))?.firstName}</strong>,</p>
                <p>Welcome back to Odin Education!</p>
                <p>We hope you're ready to inspire and educate your students today.</p>
                <p><strong style={{color: "#0c5387"}}>Please click on the MyClassroom menu from the sidebar to access your classrooms.</strong></p>
            </div>
        </Modal>
    )
}

export default TeacherMoodal