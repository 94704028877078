import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";

export function* fetchCourses() {
  yield takeEvery(actions.FETCH_COURSES, function*(action) {
    try {
      const resp = yield call(API.courses.list, action?.payload);
      yield put({
        type: actions.FETCH_COURSES_SUCCESS,
        courses: resp.data.courses,
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      console.log("err", err);
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}
export function* fetchPublishedCourses() {
  yield takeEvery(actions.FETCH_PUBLISHED_COURSES, function*(action) {
    try {
      const resp = yield call(API.courses.publishedList, action?.payload);
      yield put({
        type: actions.FETCH_PUBLISHED_COURSES_SUCCESS,
        coursesPublished: resp.data.courses,
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      console.log("err", err);
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchTeachers() {
  yield takeEvery(actions.FETCH_TEACHERS, function*(action) {
    try {
      const resp = yield call(API.classroomAdmin.listTeachers);
      yield put({
        type: actions.FETCH_TEACHERS_SUCCESS,
        classroomTeachers: resp.data.data,
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchSingleCourse() {
  yield takeEvery(actions.FETCH_COURSE_DETAILS, function*(action) {
    try {
      const resp = yield call(API.courses.details, action.courseId);
      yield put({
        type: actions.FETCH_COURSE_DETAILS_SUCCESS,
        course: resp.data,
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchSingleUnit() {
  yield takeEvery(actions.FETCH_UNIT_DETAILS, function*(action) {
    try {
      const resp = yield call(API.courses.units, action.payload);
      yield put({
        type: actions.FETCH_UNIT_DETAILS_SUCCESS,
        units: resp.data,
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* editSingleCourse() {
  yield takeEvery(actions.EDIT_SINGLE_COURSE, function*(action) {
    try {
      const resp = yield call(
        API.courses.edit,
        action.courseId,
        action.payload
      );
      yield put({
        type: actions.EDIT_SINGLE_COURSE_SUCCESS,
        course: resp.data,
      });
      yield call(action.spinHandler, false);
      const singleCourse = yield call(API.courses.details, action.courseId);
      yield put({
        type: actions.FETCH_COURSE_DETAILS_SUCCESS,
        course: singleCourse.data,
      });
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* addExistingCourse() {
  yield takeEvery(actions.ADD_EXISTING_COURSE, function*(action) {
    try {
      const resp = yield call(
        API.courses.addExisting,
        action.courseId,
        action.payload
      );
      yield put({
        type: actions.ADD_EXISTING_COURSE_SUCCESS,
        course: resp.data,
      });
      Notification("success", `${action.payload.type} added successfully`);
      yield call(
        action.spinHandler != undefined ? action.spinHandler : () => {},
        false
      );
      const respClass = yield call(API.courses.details, action.courseId);
      yield put({
        type: actions.FETCH_COURSE_DETAILS_SUCCESS,
        course: respClass.data,
      });
    } catch (err) {
      yield call(
        action.spinHandler != undefined ? action.spinHandler : () => {},
        true
      );
      Notification(
        "error",
        err && err.error
          ? err.error?.message || err?.error
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteSingleCourseFile() {
  yield takeEvery(actions.DELETE_SINGLE_COURSE_FILE, function*(action) {
    try {
      const resp = yield call(
        API.courses.deleteSingle,
        action.payload,
        action.courseId,
        action.resourceId
      );
      yield put({
        type: actions.DELETE_SINGLE_COURSE_FILE_SUCCESS,
        course: resp.data,
      });
      const singleCourse = yield call(API.courses.details, action.courseId);
      yield put({
        type: actions.FETCH_COURSE_DETAILS_SUCCESS,
        course: singleCourse.data,
      });
      Notification("success", `${action.payload.type} deleted successfully`);
      if (action?.spinHandler) yield call(action.spinHandler, false);
    } catch (err) {
      if (action?.spinHandler) yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* saveCourse() {
  yield takeEvery(actions.SAVE_COURSE, function*(action) {
    try {
      let message, response;
      let data = new FormData();
      if (action.payload.file && action.payload.file.originFileObj) {
        data.append("file", action.payload.file.originFileObj);
      }
      data.append("title", action.payload.title);
      data.append("organization", action.payload.organization);
      data.append("courseLength", action.payload.courseLength);
      if (action?.payload?.qualityEngagementFactor >= 0)
        data.append(
          "qualityEngagementFactor",
          action.payload.qualityEngagementFactor
        );
      console.log(data);
      if (action.courseId) {
        response = yield call(API.courses.edit, data, action.courseId);
        message = "Course updated successfully";
      } else {
        response = yield call(API.courses.add, data);
        message = "Course created successfully";
      }
      const courses = yield call(API.courses.list);
      yield put({
        type: actions.FETCH_COURSES_SUCCESS,
        courses: courses.data.courses,
      });
      if (action.courseId) yield call(action.cb, null);
      else yield call(action.cb, response.data.coursedata._id);
      Notification("success", message);
    } catch (err) {
      yield call(action.cb, null);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteCourse() {
  yield takeEvery(actions.DELETE_COURSE, function*(action) {
    try {
      yield call(API.courses.delete, action.courseId);
      const resp = yield call(API.courses.list);
      yield put({
        type: actions.FETCH_COURSES_SUCCESS,
        courses: resp.data.courses,
      });
      Notification("success", "Course deleted successfully");
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* getFile() {
  yield takeEvery(actions.GET_FILE, function*(action) {
    console.log("payload--->", action);
    try {
      const resp = yield call(API.courses.getLesson, action.payload);
      console.log("response>>", resp);
      yield put({
        type: actions.GET_FILE_SUCCESS,
        decryptedPath: resp.data.result,
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield put({
        type: actions.GET_FILE_SUCCESS,
        decryptedPath: null,
      });
      yield call(action.spinHandler, false);
      Notification(
        "error",
        // err && err.error ? err.error.message : "Error fetching file"
        "Lesson file corrupted. Please check the library"
      );
    }
  });
}

export function* fetchLesson() {
  // use here
  yield takeEvery(actions.FETCH_LESSON, function*(action) {
    try {
      const resp = yield call(API.courses.getLesson, action.query);
      yield call(action.urlCallBack, resp?.data?.result);
    } catch (err) {
      if (action?.urlCallBack) yield call(action.urlCallBack, false);
      Notification("error", "Error fetching the resource");
    }
  });
}

export function* addBookVersion() {
  yield takeEvery(actions.ADD_BOOK_VERSION, function*(action) {
    try {
      let data = new FormData();
      if (action.payload.cover) {
        data.append(
          "cover",
          action.payload.cover.originFileObj
            ? action.payload.cover.originFileObj
            : ""
        );
      }
      if (action.payload.file) {
        data.append(
          "file",
          action.payload.file.originFileObj
            ? action.payload.file.originFileObj
            : ""
        );
      }
      data.append("type", action.payload.type);
      data.append("title", action.payload.title);
      data.append("publication", action.payload.publication);
      data.append("description", action.payload.description);
      data.append("hashtag", JSON.stringify(action.payload.hashtag));
      data.append("fileId", action.payload.fileId);
      data.append("bookId", action.payload.bookId);
      data.append("isPublic", action.payload.isPublic);
      let message;
      if (action.payload.fileId) {
        yield call(API.books.update, data, action.payload.fileId);
        message = "File updated successfully";
      } else {
        const response = yield call(API.books.add, data);
        if (action.payload && !action.payload.fileId && response.data) {
          const updateParam = {
            type: action.payload.type || "",
            resourceId: response.data.data._id,
          };
          yield call(API.courses.addExisting, action.courseId, updateParam);
        }
        message = "File added successfully";
      }

      yield call(
        action.spinHandler != undefined ? action.spinHandler : () => {},
        false
      );
      const respClass = yield call(API.courses.details, action.courseId);
      yield put({
        type: actions.FETCH_COURSE_DETAILS_SUCCESS,
        course: respClass.data.course,
      });
      Notification("success", message);
    } catch (err) {
      yield call(action.spinHandler, "loader");
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchClassroomStudents() {
  yield takeEvery(actions.FETCH_CLASSROOM_STUDENTS, function*(action) {
    try {
      const resp = yield call(
        API.classroomAdmin.classroomAdminUsers,
        action.school_id,
        action.organization_id,
        action?.assigned
      );
      yield put({
        type: actions.FETCH_CLASSROOM_STUDENTS_SUCCESS,
        students:
          resp && resp.data && resp.data?.students ? resp.data?.students : [],
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchClassroomStudentsCstm() {
  yield takeEvery(actions.FETCH_CLASSROOM_STUDENTS_CSTM, function*(action) {
    try {
      const resp = yield call(
        API.classroomAdmin.classroomAdminUsers,
        action.school_id,
        action.organization_id,
        action?.assigned
      );
      yield put({
        type: actions.FETCH_CLASSROOM_STUDENTS_SUCCESS,
        students:
          resp && resp.data && resp.data?.students ? resp.data?.students : [],
      });
      yield call(action.spinHandler, resp.data?.students);
    } catch (err) {
      console.log(err)
      yield call(action.spinHandler, []);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* dragLessonsCourse() {
  yield takeEvery(actions.DRAG_LESSONS_COURSE, function*(action) {
    try {
      const resp = yield call(
        API.courses.dragLessons,
        action.payload,
        action.courseId
      );
      // const respClass = yield call(API.courses.details, action.courseId);
      // yield put({
      //   type: actions.FETCH_COURSE_DETAILS_SUCCESS,
      //   course: respClass.data,
      // });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchClassroomTeachers() {
  yield takeEvery(actions.FETCH_CLASSROOM_TEACHERS, function*(action) {
    try {
      const resp = yield call(
        API.classroomAdmin.classroomAdminTeachers,
        action.school_id
      );
      yield put({
        type: actions.FETCH_CLASSROOM_TEACHERS_SUCCESS,
        classroomTeachers:
          resp && resp.data && resp.data.adminUsers ? resp.data.adminUsers : [],
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

// export function* dragLessonsCourse() {
//   yield takeEvery(actions.DRAG_LESSONS, function*(action) {
//     try {
//       const resp = yield call(
//         API.courses.dragLessons,
//         action.payload,
//         action.courseId
//       );
//       const respClass = yield call(API.courses.details, action.courseId);
//       yield put({
//         type: actions.FETCH_COURSE_DETAILS_SUCCESS,
//         course: respClass.data,
//       });
//     } catch (err) {
//       yield call(action.spinHandler, false);
//       Notification(
//         "error",
//         err && err.error
//           ? err.error.message
//           : "Session timed out. Please login again"
//       );
//     }
//   });
// }

export default function* rootSaga() {
  yield all([
    fork(fetchCourses),
    fork(fetchTeachers),
    fork(saveCourse),
    fork(deleteCourse),
    fork(fetchSingleCourse),
    fork(fetchSingleUnit),
    fork(addExistingCourse),
    fork(editSingleCourse),
    fork(deleteSingleCourseFile),
    fork(getFile),
    fork(fetchClassroomStudentsCstm),
    fork(fetchClassroomStudents),
    fork(fetchClassroomTeachers),
    fork(fetchLesson),
    fork(dragLessonsCourse),
    fork(fetchPublishedCourses)
    // fork(addBookVersion),
  ]);
}
