import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import {
  getToken,
  getProfile,
  clearToken,
  formatFormData,
  formatFormData1
} from "../../helpers/utility";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";

export function* fetchUserAdmin() {
  yield takeEvery(actions.FETCH_USERS_ADMIN, function*(action) {
    try {
      const users = yield call(API.admin.list, action.payload);
      if (users) {
        yield put({
          type: actions.FETCH_USERS_ADMIN_SUCCESS,
          users: users.data.data
        });
      }
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchSingleUser() {
  yield takeEvery(actions.FETCH_USER_ADMIN_DETAILS, function*(action) {
    try {
      const resp = yield call(API.admin.details, action.userId);
      yield put({
        type: actions.FETCH_USER_ADMIN_DETAILS_SUCCESS,
        user: resp.data.user
      });
    } catch (err) {
      yield put({
        type: actions.FETCH_USER_ADMIN_DETAILS_FAIL,
        user: null
      });
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* saveUser() {
  yield takeEvery(actions.SAVE_ADMIN_USER, function*(action) {
    try {
      let resp, message;
      const payload = formatFormData1({
        ...action.payload,
        avatar:
          (action.payload.avatarList &&
            action.payload.avatarList.originFileObj) ||
          undefined,
        avatarList: undefined
      });
      if (action.userId) {
        resp = yield call(API.admin.update, payload, action.userId);
        message = "User updated successfully";
      } else {
        resp = yield call(API.admin.add, payload);
        message = "User created successfully";
      }
      Notification("success", message);
      // yield put(push("/dashboard/admin"));
      yield call(action.loader, true);
    } catch (err) {
      yield call(action.loader, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* updateAvatar() {
  yield takeEvery(actions.UPLOAD_ADMIN_AVATAR, function*(action) {
    try {
      let resp, message;
      if (action.userId) {
        const data = new FormData();
        if (action.payload.file.originFileObj) {
          data.append("avatar", action.payload.file.originFileObj);
          data.append("userid", action.userId);
          resp = yield call(API.users.uploadAvatar, data, action.userId);
          message = "Avatar updated successfully";
        }
      }
      Notification("success", message);
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteUser() {
  yield takeEvery(actions.DELETE_ADMIN, function*(action) {
    try {
      yield call(API.admin.delete, action.userId);
      const resp = yield call(API.admin.list);
      yield put({
        type: actions.FETCH_USERS_ADMIN_SUCCESS,
        users: resp.data.data
      });
      Notification("success", "User deleted successfully");
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* updateUserStatus() {
  yield takeEvery(actions.CHANGE_ADMIN_STATUS, function*(action) {
    try {
      yield call(
        API.admin.update,
        formatFormData(action.payload),
        action.userId
      );
      yield put({
        type: actions.CHANGE_ADMIN_STATUS_SUCCESS,
        userId: action.userId,
        isActive: action.payload.isActive
      });
      yield call(action.cb, false);
    } catch (err) {
      yield call(action.cb, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

// export function* fetchConfigs() {
//   yield takeEvery(actions.FETCH_CONFIGS, function*(action) {
//     try {
//       const roles = yield call(API.common.roles);
//       const schools = yield call(API.common.schools);
//       const grades = yield call(API.common.grades);
//       const subjects = yield call(API.subjects.list);
//       yield put({
//         type: actions.FETCH_CONFIGS_SUCCESS,
//         roles: roles && roles.data.roles ? roles.data.roles : [],
//         schools: schools && schools.data.schools ? schools.data.schools : [],
//         grades: grades && grades.data.grades ? grades.data.grades : [],
//         subjects:
//           subjects && subjects.data.subjects ? subjects.data.subjects : [],
//       });
//     } catch (err) {
//       yield call(action.spinHandler, false);
//       Notification(
//         "error",
//         err && err.error ? err.error.message : "Session timed out. Please login again"
//       );
//     }
//   });
// }

export function* multiDelete() {
  yield takeEvery(actions.DELETE_MULTIPLE, function*(action) {
    try {
      yield call(API.users.multiDelete, action.payload.userIds);
      const resp = yield call(API.users.list);
      yield put({
        type: actions.FETCH_USERS_ADMIN_SUCCESS,
        users: resp.data.data
      });
      Notification("success", "Users Removed successfully");
      yield call(action.payload.callBack, false);
    } catch (err) {
      yield call(action.payload.callBack, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchOrganizationSchool() {
  yield takeEvery(actions.FETCH_ORGANIZATION_SCHOOL, function*(action) {
    try {
      const resp = yield call(
        API.common.organizationSchools,
        action.organization_id
      );
      yield put({
        type: actions.FETCH_ORGANIZATION_SCHOOL_SUCCESS,
        schools: resp && resp.data.schools ? resp.data.schools : []
      });
      yield call(action.spinner, false);
    } catch (err) {
      yield call(action.spinner, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchOrganizationCourse() {
  yield takeEvery(actions.FETCH_ORGANIZATION_COURSE, function*(action) {
    try {
      const resp = yield call(
        API.common.organizationCourses,
        action.organization_id
      );
      yield put({
        type: actions.FETCH_ORGANIZATION_COURSE_SUCCESS,
        courses: resp && resp.data.courses ? resp.data.courses : []
      });
      yield call(action.spinner, false);
    } catch (err) {
      // Notification(
      //   "error",
      //   err && err.error
      //     ? err.error.message
      //     : "Session timed out. Please login again"
      // );
    }
  });
}

export function* fetchOrganization() {
  yield takeEvery(actions.FETCH_ORGANIZATION, function*(action) {
    try {
      const organizations = yield call(API.common.listOrganization);
      yield put({
        type: actions.FETCH_ORGANIZATION_SUCCESS,
        organizations:
          organizations && organizations.data ? organizations.data.organizations : []
      });
      yield call(action.spinner, false);
    } catch (err) {
      // Notification(
      //   "error",
      //   err && err.error
      //     ? err.error.message
      //     : "Session timed out. Please login again"
      // );
    }
  });
}

export function* fetchTeachers() {
  yield takeEvery(actions.FETCH_TEACHER, function*(action) {
    try {
      const resp = yield call(API.common.teachers, action.id);
      yield put({
        type: actions.FETCH_TEACHER_SUCCESS,
        teachers: resp && resp.data.users ? resp.data.users : []
      });
      yield call(action.spinner, false);
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchOrganizationSchoolCstm() {
  yield takeEvery(actions.FETCH_ORGANIZATION_SCHOOL_CSTM, function*(action) {
    try {
      const resp = yield call(
        API.common.organizationSchools,
        action.organization_id
      );
      yield put({
        type: actions.FETCH_ORGANIZATION_SCHOOL_SUCCESS,
        schools: resp && resp.data.schools ? resp.data.schools : []
      });
      yield call(action.spinner,resp.data.schools);
    } catch (err) {
      yield call(action.spinner,[]);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchOrganization),
    fork(fetchUserAdmin),
    fork(saveUser),
    fork(updateAvatar),
    fork(deleteUser),
    fork(fetchOrganizationSchool),
    fork(fetchOrganizationSchoolCstm),
    fork(fetchOrganizationCourse),
    fork(fetchSingleUser),
    fork(updateUserStatus),
    fork(multiDelete),
    fork(fetchTeachers)
  ]);
}
