/**
 * Auth selectors
 */

import { createSelector } from "reselect";

const selectAuth = (state) => state.Auth;

const getProfile = () =>
  createSelector(selectAuth, (profileState) => {
    return profileState.get("profile");
  });

const getBlockedMenuList = () =>
  createSelector(selectAuth, (profileState) => {
    return profileState.getIn(["profile"])["role"] ? profileState.getIn(["profile"])["blockedMenus"] : [];
  });

const getToken = () =>
  createSelector(selectAuth, (tokenState) => tokenState.get("token"));

const getRole = () =>
  createSelector(selectAuth, (profileState) => {
    return profileState.getIn(["profile"])["userType"];
  });

const getQualityPermission = () =>
  createSelector(selectAuth, (profileState) => {
    return profileState.getIn(["profile"])["qualityEngagementFactorPermission"];
  });

const getSchool = () =>
  createSelector(selectAuth, (profileState) => {
    if (profileState.getIn(["profile"])["school"])
      return {
        name: profileState.getIn(["profile"])["school"]["name"],
        id: profileState.getIn(["profile"])["school"]["_id"],
      };
    return {};
  });

const getOrganizationsAuth = () =>
  createSelector(selectAuth, (profileState) => {
    return profileState.getIn(["profile"])["organizations"];
  });

const getUserSchool = () =>
  createSelector(selectAuth, (profileState) => {
    console.log(profileState.getIn(["profile"]))
    return profileState.getIn(["profile"])["schools"];
  });

const getUserClassroom = () =>
  createSelector(selectAuth, (profileState) => {
    return profileState.getIn(["profile"])["classrooms"];
  });

const getQuickboxId = () =>
  createSelector(selectAuth, (profileState) => {
    return profileState.getIn(["profile"])["quickblox"];
  });

export {
  getProfile,
  getToken,
  getBlockedMenuList,
  getRole,
  getSchool,
  getOrganizationsAuth,
  getUserSchool,
  getUserClassroom,
  getQuickboxId,
  getQualityPermission
};
