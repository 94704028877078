import { fromJS } from "immutable";
import actions from "./actions";

const initState = fromJS({
  rewards: [],
  totalCount: 0,
  loading: false,
  status: "",
  error: "",
  schools: [],
});

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_REWARDS_LOADING:
      return state.set("loading", action.loading);

    case actions.SET_REWARDS_ERROR:
      return state.set("error", action.error);

    case actions.UPLOAD_REWARDS_FILE:
      return state.set("status", "");

    case actions.UPLOAD_REWARDS_FILE_SUCCESS:
      return state.set("status", action.status);

    case actions.RESET:
      return state.set("loading", false).set("error", "");

    case actions.REQUEST_REWARDS_SUCCESS:
      return state
        .set("rewards", action.rewards?.requests)
        .set("totalCount", action.rewards?.pagination?.totalCount);
    case actions.FETCH_SCHOOLS_SUCCESS:
      return state.set("schools", action.schools).set("loading", false);
    default:
      return state;
  }
}
