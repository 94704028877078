import { all } from "redux-saga/effects";
import appVersionSagas from "./appVersions/saga";
import otherAppVersionSagas from "./chromeVersions/saga";
import authSagas from "./auth/saga";
import bookSagas from "./books/saga";
import deviceSagas from "./devices/saga";
import gradeSagas from "./grades/saga";
import groupSagas from "./chatgroups/saga";
import importSagas from "./imports/saga";
import notificationSagas from "./notifications/saga";
import psmSagas from "./psm/saga";
import rewardSagas from "./rewards/saga";
import schoolSagas from "./schools/saga";
import organizationSagas from "./organizations/saga";
import subjectSagas from "./subjects/saga";
import courseSagas from "./Courses/saga";
import classroomAdminSagas from "./classroomAdmin/saga";
import myClassroomAdminSagas from "./MyClassroom/saga";
import surveySagas from "./surveys/saga";
import testSagas from "./test/saga";
import userSagas from "./users/saga";
import websiteSagas from "./websites/saga";
import roleSagas from "./role/saga";
import adminSaga from "./admin/saga";
import healthSaga from "./health/saga";
import dashboard from "./dashboard/saga";
import App from "./app/saga";
import Zoom from "./zoom/saga";
import lessonBuilder from "./lessonBuilder/saga";
import bigBlue from "./bigBlue/saga";
import Area from "./area/saga";
import Type from "./Type/saga";
import investors from "./investors/saga";
import DeviceTransaction from "./deviceTransaction/saga"


export default function* rootSaga(getState) {
  yield all([
    appVersionSagas(),
    otherAppVersionSagas(),
    authSagas(),
    bookSagas(),
    deviceSagas(),
    gradeSagas(),
    groupSagas(),
    importSagas(),
    notificationSagas(),
    psmSagas(),
    rewardSagas(),
    schoolSagas(),
    organizationSagas(),
    subjectSagas(),
    courseSagas(),
    classroomAdminSagas(),
    myClassroomAdminSagas(),
    surveySagas(),
    testSagas(),
    userSagas(),
    websiteSagas(),
    roleSagas(),
    adminSaga(),
    dashboard(),
    healthSaga(),
    App(),
    Zoom(),
    bigBlue(),
    lessonBuilder(),
    Area(),
    Type(),
    investors(),
    DeviceTransaction()
  ]);
}
