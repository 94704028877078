export default {
  baseUrl: "https://omanghub-uat-api.cubettech.in",
  portalUrl: "https://omanghub-live-api.cubettech.in",
  productionUrl: "https://omanghub.cubettech.in",
  s3Url: "https://omangapp-uat.s3.amazonaws.com"
};
export const qbAdminId = "132060393";

const siteConfig = {
  siteName: "Omang",
  siteIcon: "ion-flash",
  footerText: "Omang ©2020",
};
const themeConfig = {
  topbar: "themedefault",
  sidebar: "themedefault",
  layout: "themedefault",
  theme: "themedefault",
};
const language = "english";
export { siteConfig, language, themeConfig };
