import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";

export function* saveInvestor() {
  let message = "";
  yield takeEvery(actions.SAVE_INVESTOR, function*(action) {
    try {
      let { payload } = action;
      if (action.investorId) {
        yield call(API.investors.update, payload, action.investorId);
        message = "Investor updated successfully";
      } else {
        yield call(API.investors.add, payload);
        message = "Investor added successfully";
      }
      yield call(action.cb, "success");
      Notification("success", message);
    } catch (err) {
      yield call(action.cb, "error");
      console.log("error:", err);
      Notification(
        "error",
        err && err.error ? err.error : "Investor update error"
      );
    }
  });
}

export function* deleteInvestor() {
  yield takeEvery(actions.DELETE_INVESTOR, function*(action) {
    try {
      const response = yield call(API.investors.delete, action.investorId);
      if (response.statusCode == 200) {
        Notification("success", "Investor deleted successfully");
        yield call(action.spinHandler, "success");
      } else {
        Notification("error", "Investor delete failed");
        yield call(action.spinHandler, "error");
      }
    } catch (err) {
      yield call(action.spinHandler, "error");
      console.log("error:", err);
      Notification("error", "Error");
    }
  });
}
export function* fetchAllInvestors() {
  yield takeEvery(actions.FETCH_ALL_INVESTORS, function*(action) {
    try {
      const response = yield call(API.investors.allInvestors);
      yield put({
        type: actions.FETCH_ALL_INVESTORS_SUCCESS,
        allInvestors: response?.data?.sponsors || [],
      });

      if (action.spinHandler) yield call(action.spinHandler);
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
      if (action.spinHandler) yield call(action.spinHandler);
    }
  });
}

export function* fetchAllProposalNumbers() {
  yield takeEvery(actions.FETCH_ALL_PROPOSAL_NUMBERS, function*(action) {
    try {
      const response = yield call(API.investors.allInvestors);
      const proposalNumbers = response.data?.sponsors?.reduce(
        (merged, block) => {
          const nos = block?.proposalDetails
            ?.filter((d) => d?.proposalNumber)
            ?.map((d) => d?.proposalNumber);
          if (nos?.length) merged.push(...nos);
          return merged;
        },
        []
      );
      yield put({
        type: actions.FETCH_ALL_PROPOSAL_NUMBERS_SUCCESS,
        allProposalNumbers: [...new Set(proposalNumbers)] || [],
      });

      if (action.spinHandler) yield call(action.spinHandler);
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
      if (action.spinHandler) yield call(action.spinHandler);
    }
  });
}

export function* fetchProposals() {
  yield takeEvery(actions.FETCH_PROPOSALS, function*(action) {
    try {
      const response = yield call(API.investors.proposals, action.payload);
      yield put({
        type: actions.FETCH_PROPOSALS_SUCCESS,
        list: response?.data?.data || [],
      });
      if (action?.spinHandler) yield call(action.spinHandler);
    } catch (err) {
      Notification(
        "error",
        err?.error?.message || "Session timed out. Please login again"
      );
      if (action?.spinHandler) yield call(action.spinHandler);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(saveInvestor),
    fork(deleteInvestor),
    fork(fetchProposals),
    fork(fetchAllInvestors),
    fork(fetchAllProposalNumbers),
  ]);
}
