import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";
import { formatObject } from "../../helpers/utility";

const { fetchHistoryMeetingSuccess, fetchHistory } = actions;

const formatResponse = (res) => {
  return res.map((item) => ({
    _id: item._id,
    name: `${item.firstName} ${item.lastName || ""}`,
  }));
};

export function* fetchHistoryBBB() {
  yield takeEvery(actions.GET_HISTORY_MEETING, function*(action) {
    try {
      const resp = yield call(API.bbm.get, action.payload);
      yield put(fetchHistoryMeetingSuccess(resp.data.data));
      yield call(action.callBack, false);
    } catch (err) {
      console.log(err);
      yield call(action.callBack, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* saveBbm() {
  yield takeEvery(actions.SAVE_BBM, function*(action) {
    try {
      let resp;
      if (action.edit) {
        resp = yield call(API.bbm.edit, action.payload, action.edit);
      } else {
        resp = yield call(API.bbm.save, action.payload);
      }
      yield put(fetchHistory(action.callBack, action.bbmParam));
      if (action.edit) Notification("success", "Meeting has been updated");
      else Notification("success", "Meeting has been added");
    } catch (err) {
      yield call(action.callBack, "error");
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteBbm() {
  yield takeEvery(actions.DELETE_BBM, function*(action) {
    try {
      const resp = yield call(API.bbm.delete, action.id);
      yield call(action.reloadCb, false);
      Notification("success", "Meeting has been deleted");
    } catch (err) {
      yield call(action.callBack, false);
      Notification("error", "Something unexpected happened");
    }
  });
}

export function* endCall() {
  yield takeEvery(actions.END_CALL, function*(action) {
    try {
      const resp = yield call(API.bbm.endCall, action.id);
      // yield put(fetchHistory(action.callBack, false));
      yield call(action.reloadCb, false);
      Notification("success", "Call Terminated");
    } catch (err) {
      yield call(action.callBack, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchConfigs() {
  yield takeEvery(actions.FETCH_CONFIGS_BBM, function*(action) {
    try {
      const schools = yield call(API.common.schools);
      const classrooms = yield call(API.common.classrooms)
      // const grades = yield call(API.common.grades);
      // const subjects = yield call(API.subjects.list);
      const role = yield call(API.common.roles);
      yield put({
        type: actions.FETCH_CONFIGS_SUCCESS_BBM,
        schools: schools && schools.data.schools ? schools.data.schools : [],
        grades: /*grades && grades.data.grades ? grades.data.grades : */ [],
        classrooms:classrooms && classrooms?.data?.classrooms || [],
        subjects: [],
        // subjects && subjects.data.subjects ? subjects.data.subjects : [],
        role: role && role.data.roles ? role.data.roles : [],
      });
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchUserList() {
  yield takeEvery(actions.FETCH_USER_LIST_BBB, function*(action) {
    try {
      const { payload } = action;
      Object.keys(payload).forEach((key) => {
        if (payload[key] === undefined) {
          delete payload[key];
        }
      });

      const resp = yield call(API.users.list, formatObject(action.payload));
      if (!resp.data) {
        Notification("error", "No users found for this combination!");
      }

      yield put({
        type: actions.FETCH_USER_LIST_BBB_SUCCESS,
        users: resp.data ? formatResponse(resp.data) : [],
      });
      yield call(action.callBack, false);
    } catch (err) {
      yield call(action.callBack, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchHistoryBBB),
    fork(fetchConfigs),
    fork(saveBbm),
    fork(deleteBbm),
    fork(fetchUserList),
    fork(endCall),
  ]);
}
