import { fromJS } from "immutable";
import actions from "./actions";

const initState = fromJS({
  subjects: null,
  key: null,
});

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_SUBJECTS_SUCCESS:
      return state.set("subjects", action.subjects);
    case actions.FETCH_SUBJECTS_AUTO_COMPLETE_SUCCESS:
      return state.set("subjectsAutoComplete", action.subjects);
    case actions.SET_FILTER:
      return state.set("key", action.key);
    case actions.RESET:
      return state.set("subjects", null);
    default:
      return state;
  }
}
